import React, { useState, useEffect } from 'react'

import brokenImg from '../../../../assets/img/broken-image-general.png';

export const ProductosFactura = (props) => {

  var Productos = props.item

  const handleImageError = (event) => {
    event.target.src = brokenImg; // Replace with your default image URL
  };

  return (
    <tr >
      <td className='text-center'>
        <img src={`https://www.belmenyonline.com/catalogo/${Productos.Codigo}.png`} onError={handleImageError} alt="" />
      </td>
      <td className='fw-bold text-danger text-center'>
        {Productos.Codigo}
      </td>
      <td className='fw-bold'>
        {Productos.Nombre}
      </td>
      <td className='fw-bold text-success text-center'>
        ${Productos.PrecioUnitario.toFixed(2)}
      </td>
      <td className='fw-bold text-center'>
        {Productos.Cantidad} UD
      </td>
      <td className='fw-bold text-success text-center'>
        ${Productos.Subtotal.toFixed(2)}
      </td>
    </tr>
  )
}
