import React from 'react'

import { IoMailUnread, IoPaperPlane } from "react-icons/io5";

import belmenyLogo from '../assets/img/logo-png.webp';

export const Footer = () => {
  return (
    <>
      <div className="footer bg-belmeny-gradient mt-5">
        <div className="container text-light pt-3 pb-3">
          <div className="row text-center">
            <div className="col">
              Direccion:
              <p>Belmeny Group Caracas <br />
              Belmeny Group Maracaibo</p>
            </div>
            <div className="col">
              <img width={80} src={belmenyLogo} alt="Belmeny Logo" className='text-center drop-shadow-sm' />
              <br />
            </div>
            <div className="col">
              Contacto:
              <p><IoPaperPlane /> 0424-6589484 <br />
                <IoMailUnread /> vert@belmenyonline.com</p>
            </div>
          </div>
          <hr />
          {/* 2022 <small>Belmeny Group © {new Date().getFullYear()}</small> */}
          <small>Belmeny Group © 2023</small>
        </div>
      </div>
    </>
  )
}
