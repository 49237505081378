import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

import Layout from '../../components/Layout';
import AuthUser from '../../components/AuthUser';
import { PedidosPorDiaTable } from '../../components/PedidosPorDiaTable';
import { TopClientes } from '../../components/modulos_gerente/TopClientes';
import { HistoricoCliente } from '../../components/modulos_gerente/HistoricoCliente';
import { TopVendedores } from '../../components/modulos_gerente/TopVendedores';
import { TopProductosSupervisor } from '../../components/modulos_gerente/TopProductosSupervisor';
import { CorteSemanalSupervisor } from '../../components/modulos_gerente/CorteSemanalSupervisor'
import { CobranzasGerencia } from '../../components/modulos_gerente/CobranzasGerencia'
import { ModulosSupervisor } from '../../data/menuData';

import belmenyLogo from '../../assets/img/logoBG.webp';
import otherUserIcon from '../../assets/img/user-icon-resize.webp';
import notFoundImg from '../../assets/img/404-module-not-found-2.webp';
import { CircleUserRound, ChevronRight, ClipboardList, TrendingUp, TrendingDown } from 'lucide-react';
import { RiCloseCircleFill } from "react-icons/ri";

import { getTotalClientesxSupervisor, getClientesAtendidosxZona } from '../../api/requestClientes';
import { getTopVendedoresSupervisor, getVendedoresXSupervisor, getZonasSupervisor, getCorteSemanalxZona, getVentasZona, getCantidadVentasxGerente, getPedidosPorVendedor, getVentasAnualesEnCursoSupervisores, getTopClientes, getCobranzasSupervisor, getFacturasVencidas, getFacturasEmitidas, getParetos, getClientesCaptados } from '../../api/requestSupervisor';

import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import axios from 'axios';

import Chart from "react-apexcharts";
import { DateRangePicker, Stack } from 'rsuite';

import { startOfMonth, endOfMonth, addMonths, startOfWeek, endOfWeek, subWeeks, format } from 'date-fns';
import moment from 'moment/moment';

import { formatNumber } from '../../utils/formats';

// SVG LOGOS
import brokenImg from '../../assets/img/broken-image-general.png';

export const DashboardSupervisor = () => {

  useEffect(() => {
    loadVentas()
    CheckImg()
  }, []);

  // State del usuario
  const { user, getToken } = AuthUser();
  const navigate = useNavigate();

  // State del loading
  const [loading, setLoading] = useState(true);

  // States del modal
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modalLoading, setModalLoading] = useState("");

  // State de datos
  const [username, setUsername] = useState('');
  const [userIcon, setUserIcon] = useState(``);
  const [zonaSelected, setZonaSelected] = useState('');
  const [clienteSelected, setClienteSelected] = useState('');
  const [mesSelected, setMesSelected] = useState('');
  const [añoSelected, setAñoSelected] = useState(0);
  const [totalVendido, setTotalVendido] = useState(0)
  const [totalMeta, setTotalMeta] = useState(0)
  const [totalClientes, setTotalClientes] = useState(0)
  const [totalVendidoVert, setTotalVendidoVert] = useState(0)
  const [totalVendidoIngco, setTotalVendidoIngco] = useState(0)
  const [totalVendidoImou, setTotalVendidoImou] = useState(0)
  const [totalVendidoQuilosa, setTotalVendidoQuilosa] = useState(0)
  const [totalVendidoFleximatic, setTotalVendidoFleximatic] = useState(0)
  const [totalVendidoCorona, setTotalVendidoCorona] = useState(0)
  const [totalVendidoWadfow, setTotalVendidoWadfow] = useState(0)
  const [facturasEmitidas, setFacturasEmitidas] = useState(0);
  const [clientesCaptados, setClientesCaptados] = useState(0);
  const [resumenFacturado, setResumenFacturado] = useState(0);
  const [resumenPedido, setResumenPedido] = useState(0);
  const [multipleTops, setMultipleTops] = useState(false);
  const [listVendedores, setListVendedores] = useState([]);
  const [zonasSupervisor, setZonasSupervisor] = useState([]);
  const [ventasSemanales, setVentasSemanales] = useState([]);
  const [ventasSemanalesOtrasZonas, setVentasSemanalesOtrasZonas] = useState([]);
  const [ventasGralZona, setVentasGralZona] = useState([]);
  const [ventasProductos, setVentasProductos] = useState([]);
  const [pedidosPorDia, setPedidosPorDia] = useState([]);
  const [facturasVencidas, setFacturasVencidas] = useState({});
  const [resultCobranzas2023, setResultCobranzas2023] = useState({})
  const [resultCobranzas2024, setResultCobranzas2024] = useState({})
  const [topClientes, setTopClientes] = useState({});
  const [topAllClientes, setTopAllClientes] = useState({});
  const [ventasAnualesData, setVentasAnualesData] = useState({});
  const [ventasZonaData, setVentasZonaData] = useState({});
  const [clientesChart, setClientesChart] = useState({})
  const [topData, setTopData] = useState({});
  const [topData2, setTopData2] = useState({});
  const [topData3, setTopData3] = useState({});

  const flete = 0.031

  // Calculate the start and end of the past week
  const pastWeekStart = startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 });
  const pastWeekEnd = endOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 });

  // Calculate the start and end of the current week
  const currentWeekStart = startOfWeek(new Date(), { weekStartsOn: 1 });
  const currentWeekEnd = endOfWeek(new Date(), { weekStartsOn: 1 });

  // Format the dates as required (dd/mm/yyyy)
  const formattedPastWeekStart = format(pastWeekStart, 'dd/MM');
  const formattedPastWeekEnd = format(pastWeekEnd, 'dd/MM');
  const formattedCurrentWeekStart = format(currentWeekStart, 'dd/MM');
  const formattedCurrentWeekEnd = format(currentWeekEnd, 'dd/MM');

  // Use the values in your state or wherever you need them
  const [startPastWeek, setStartPastWeek] = useState(formattedPastWeekStart);
  const [endPastWeek, setEndPastWeek] = useState(formattedPastWeekEnd);
  const [startCurrentWeek, setStartCurrentWeek] = useState(formattedCurrentWeekStart);
  const [endCurrentWeek, setEndCurrentWeek] = useState(formattedCurrentWeekEnd);
  const [startActualMonth, setStartActualMonth] = useState(format(startOfMonth(new Date()), 'yyyy-MM-dd HH:mm:ss'))
  const [endActualMonth, setEndActualMonth] = useState(format(endOfMonth(new Date()), 'yyyy-MM-dd HH:mm:ss'))

  const predefinedRanges = [
    {
      label: 'Últimos 3 meses',
      value: [startOfMonth(addMonths(new Date(), -3)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'bottom'
    },
    {
      label: 'Últimos 6 meses',
      value: [startOfMonth(addMonths(new Date(), -6)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'bottom'
    },
    {
      label: 'Último año',
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
      placement: 'bottom'
    }
  ];

  const { afterToday } = DateRangePicker;

  const labels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

  const handleImageError = (event) => {
    event.target.src = brokenImg;
  };

  const handleButtonClick = (buttonId) => {
    setShow(true);
    switch (buttonId) {
      case 'pedidosPorDia':
        setModalTitle("Resumen de pedidos por día")
        setModalBody("pedidosPorDia")
        setModalLoading("resumen de pedidos por día")
        break;
      case 'corteSemanal':
        setModalTitle("Corte semanal")
        setModalBody("corteSemanal")
        setModalLoading("corte semanal")
        break;
      case 'ranking':
        setModalTitle("Top 10 vendedores del mes")
        setModalBody("rankingVendedores")
        setModalLoading("top de vendedores")
        break;
      case 'cobranzasZona':
        setModalTitle("Cobranzas por Zona")
        setModalBody("cobranzasZona")
        setModalLoading("cobranzas por zona")
        break;
      case 'topClientes':
        setModalTitle("Top Clientes")
        setModalBody("topClientes")
        setModalLoading("top de clientes")
        break;
      case 'topAllClientes':
        loadTopAllClientes()
        setModalTitle("Top Clientes")
        setModalBody("topAllClientes")
        setModalLoading("top de clientes")
        break;
      case 'topProductos':
        setModalTitle("Top Productos")
        setModalBody("topProductos")
        setModalLoading("top de productos")
        break;
      case 'topVendedores':
        setModalTitle("Top Vendedores")
        setModalBody("topVendedores")
        setModalLoading("top de vendedores")
        break;
      case 'cobranzasGerencia':
        setModalTitle("Cobranzas de gerencia")
        setModalBody("cobranzasGerencia")
        setModalLoading("sus cobranzas")
        break;
      case 'historicoCliente':
        setModalTitle("Historico del Cliente")
        setModalBody("historicoCliente")
        setModalLoading("historico del cliente")
        break;
      default:
        setModalTitle("Resumen de pedidos por día")
        setModalBody("pedidosPorDia")
        setModalLoading("resumen de pedidos por día")
        break;
    }
  };

  const CheckImg = async () => {
    await axios.get(`https://belmenyonline.com/fotos_vendedores/${user.CodVendedor}.jpeg`).then(() => {
      setUserIcon(`https://belmenyonline.com/fotos_vendedores/${user.CodVendedor}.jpeg`)
      return true;
    })
      .catch(() => {
        setUserIcon(otherUserIcon)
        return false;
      });
  }

  const changeDate = async (e) => {
    setLoading(true)
    let date = JSON.stringify(e)
    let start = date.slice(2, 12) + " 00:00:00"
    let end = date.slice(29, 39) + " 23:59:59"

    let resCalendar;

    if (zonaSelected === 'General') {
      resCalendar = await getPedidosPorVendedor(start, end, zonaSelected, user.CodVendedor)
    } else {
      resCalendar = await getPedidosPorVendedor(start, end, zonaSelected)
    }

    // Define your desired date range based on start and end dates
    const startDate = new Date(start);
    const endDate = new Date(end);

    // Create an array of dates within the date range
    const dateRange = [];
    for (let currentDate = startDate; currentDate <= endDate; currentDate.setDate(currentDate.getDate() + 1)) {
      dateRange.push(currentDate.toISOString().slice(0, 10));
    }

    // Create a set of unique dates from your data
    const uniqueDates = new Set(resCalendar.pedidosPorVendedor.map(entry => entry.Fecha));

    // Create a list of vendors
    const uniqueVendors = new Map();
    // Create a mapping of Vendedor or CodVendedor to their corresponding data
    const totalPedidoMap = new Map();
    const totalFacturadoMap = new Map();
    const countPedidosMap = new Map();

    resCalendar.nroPedidos.forEach(item => {
      countPedidosMap.set(item.Vendedor, item.nroPedidos);
    })

    resCalendar.nroPedidos.forEach(item => {
      totalPedidoMap.set(item.Vendedor, item.TotalPedido);
    });

    resCalendar.nroFacturas.forEach(item => {
      totalFacturadoMap.set(item.CodigoVendedor, item.TotalFacturado);
    });

    // Add TotalPedido and TotalFacturado to each item in pedidosPorVendedor
    const updatedPedidosPorVendedor = resCalendar.pedidosPorVendedor.map(item => {
      const codVendedor = item.CodVendedor;

      return {
        ...item,
        nroPedidos: countPedidosMap.get(codVendedor) || 0,
        TotalPedido: totalPedidoMap.get(codVendedor) || 0,
        TotalFacturado: totalFacturadoMap.get(codVendedor) || 0,
      };
    });

    // Iterate over vendors and dates to fill in missing entries
    updatedPedidosPorVendedor.forEach(vendor => {
      // If the vendor is not already in the map, add it
      if (!uniqueVendors.has(vendor.CodVendedor)) {
        uniqueVendors.set(vendor.CodVendedor, { CodVendedor: vendor.CodVendedor, Nombre: vendor.Nombre, nroPedidos: vendor.nroPedidos, TotalPedido: vendor.TotalPedido, TotalFacturado: vendor.TotalFacturado });
      }
    });

    // Create a new array to store the filled data
    const filledData = [];

    // Iterate over unique vendors and dates to fill in missing entries
    uniqueVendors.forEach(vendor => {
      dateRange.forEach(date => {
        const existingEntry = updatedPedidosPorVendedor.find(entry => entry.CodVendedor === vendor.CodVendedor && entry.Fecha === date);
        if (existingEntry) {
          filledData.push({ Pedidos: existingEntry.Pedidos, CodVendedor: vendor.CodVendedor, Nombre: vendor.Nombre, Fecha: date, nroPedidos: vendor.nroPedidos, TotalPedido: vendor.TotalPedido, TotalFacturado: vendor.TotalFacturado });
        } else {
          // If the entry is missing, add it with 0 orders
          filledData.push({ Pedidos: 0, CodVendedor: vendor.CodVendedor, Nombre: vendor.Nombre, Fecha: date, nroPedidos: vendor.nroPedidos, TotalPedido: vendor.TotalPedido, TotalFacturado: vendor.TotalFacturado });
        }
      });
    });

    // Sort the filledData array by 'CodVendedor' (vendor code) and then by 'Fecha' (date)
    filledData.sort((a, b) => {
      if (a.CodVendedor !== b.CodVendedor) {
        return a.CodVendedor.localeCompare(b.CodVendedor); // Sort by vendor code
      } else {
        const dateA = new Date(a.Fecha);
        const dateB = new Date(b.Fecha);
        return dateA - dateB; // Sort by date
      }
    });

    setPedidosPorDia(filledData);
    const totalFactSinFlete = resCalendar.nroFacturas.reduce((acc, factura) => acc + factura.TotalFacturado, 0) - (resCalendar.nroFacturas.reduce((acc, factura) => acc + factura.TotalFacturado, 0) * flete)
    const totalPedSinFlete = resCalendar.nroPedidos.reduce((acc, factura) => acc + factura.TotalPedido, 0) - (resCalendar.nroPedidos.reduce((acc, factura) => acc + factura.TotalPedido, 0) * flete)
    setResumenFacturado(totalFactSinFlete);
    setResumenPedido(totalPedSinFlete);

    setLoading(false)
  }

  const loadTopAllClientes = async () => {
    setLoading(true)
    const resTopClientes = await getTopClientes(user.CodVendedor)
    const topDeClientes = await resTopClientes
    setTopAllClientes(topDeClientes)
    setLoading(false)
  }

  const loadVentas = async () => {
    setUsername(user.CodGerente)

    const [resListaVendedores, resZonasSup, resVentasxZona, resCorteSemanal, resCorteSemanalOtrasZonas, resClientesFacturados, resVentasYCantidad, resTotalClientes, resVentasAnualesEnCurso, resTopClientes, resCobranza2023, resCobranza2024, resFacturasVencidas, resFacturasEmitidas, resClientesCaptados] = await Promise.all([getVendedoresXSupervisor(user.CodVendedor), getZonasSupervisor(user.CodVendedor), getVentasZona(user.CodVendedor), getCorteSemanalxZona(user.CodVendedor), getCorteSemanalxZona(user.CodVendedor, 'si'), getClientesAtendidosxZona(user.CodVendedor), getCantidadVentasxGerente(user.CodVendedor), getTotalClientesxSupervisor(user.CodVendedor), getVentasAnualesEnCursoSupervisores(user.CodVendedor), getParetos(user.CodVendedor, null, "Facturado"), getCobranzasSupervisor(user.CodVendedor, 2023), getCobranzasSupervisor(user.CodVendedor, 2024), getFacturasVencidas(user.CodVendedor), getFacturasEmitidas(user.CodVendedor), getClientesCaptados(user.CodVendedor)])

    const listaVendedores = await resListaVendedores;
    const zonasSup = await resZonasSup;
    const ventasxZona = await resVentasxZona;
    const corteSemanal = await resCorteSemanal;
    const corteSemanalOtrasZonas = await resCorteSemanalOtrasZonas;
    const clientesFacturados = await resClientesFacturados;
    const ventasYCantidad = await resVentasYCantidad;
    const totClientes = await resTotalClientes;
    const ventasAnuales = await resVentasAnualesEnCurso
    const topClientes = await resTopClientes
    const resCobranzasGral2023 = await resCobranza2023
    const resCobranzasGral2024 = await resCobranza2024
    const facturasVencidas = await resFacturasVencidas
    const facturasEmitidas = await resFacturasEmitidas;
    const clientesCapts = await resClientesCaptados;

    setListVendedores(listaVendedores)
    setZonasSupervisor(zonasSup)
    setVentasSemanales(corteSemanal)
    setVentasSemanalesOtrasZonas(corteSemanalOtrasZonas)
    setVentasGralZona(ventasxZona)
    setVentasProductos(ventasYCantidad)
    setTotalClientes(totClientes);
    setTopClientes(topClientes)
    setZonaSelected(zonasSup[0].Sector)
    setResultCobranzas2023(resCobranzasGral2023)
    setResultCobranzas2024(resCobranzasGral2024)
    setFacturasVencidas(facturasVencidas)
    setFacturasEmitidas(facturasEmitidas.FacturasEmitidas)
    setClientesCaptados(clientesCapts.ClientesCaptados)

    // Bar chart ventas del mes
    setVentasZonaData({
      options: {
        chart: {
          id: "chart-VentasZona",
        },
        xaxis: {
          categories: ventasxZona.map((Zona) => `${Zona.Sector} - ${Zona.meta_zona} USD ≈ ${((((Zona.total_vendido) - (Zona.total_vendido * 0.031)) * 100) / Zona.meta_zona).toFixed(2)}% `)
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: false,
          }
        },
        colors: ['#27ae60'],
        dataLabels: {
          formatter: function (val, opt) {
            const goals =
              opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex]
                .goals
            if (goals && goals.length) {
              return `${val} / ${goals[0].value}`
            }
            return val
          },
          enabled: true,
          style: {
            colors: ['#000']
          }
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          customLegendItems: ['Ventas actuales', 'Ventas esperadas'],
          markers: {
            fillColors: ['#27ae60', '#e74c3c']
          }
        }
      },
      series: [
        {
          name: 'Ventas en USD',
          data: ventasxZona.map((Zona) => ({
            x: Zona.Sector,
            y: ((Zona.total_vendido.toFixed(2)) - (Zona.total_vendido.toFixed(2) * 0.031)).toFixed(2),
            goals: [
              {
                name: 'Meta en USD',
                value: Zona.meta_zona,
                strokeWidth: 85,
                strokeHeight: 5,
                strokeLineCap: 'round',
                strokeColor: '#e74c3c'
              }
            ]
          }))
        }
      ]
    })

    // Donut chart de clientes facturados en el mes
    setClientesChart({
      options:
      {
        labels: clientesFacturados.map((Zona) => `${Zona.Sector}`), // Replace with your own labels
        legend: {
          show: true,
          position: "bottom",
        },
        plotOptions: {
          pie: {
            donut: {
              size: "70%",
              labels: {
                show: true
              }
            },
            dataLabels: {
              enabled: true,
            },
          },
        },
      },
      series: clientesFacturados.map((Zona) => Zona.uniqueClientes)
    })

    const resCalendar = await getPedidosPorVendedor(startActualMonth, endActualMonth, zonasSup[0].Sector)

    // Create a set of unique dates
    const uniqueDates = new Set(resCalendar.pedidosPorVendedor.map(entry => entry.Fecha));

    // Create a list of vendors
    const uniqueVendors = new Map();
    // Create a mapping of Vendedor or CodVendedor to their corresponding data
    const totalPedidoMap = new Map();
    const totalFacturadoMap = new Map();
    const countPedidosMap = new Map();

    resCalendar.nroPedidos.forEach(item => {
      countPedidosMap.set(item.Vendedor, item.nroPedidos);
    })

    resCalendar.nroPedidos.forEach(item => {
      totalPedidoMap.set(item.Vendedor, item.TotalPedido);
    });

    resCalendar.nroFacturas.forEach(item => {
      totalFacturadoMap.set(item.CodigoVendedor, item.TotalFacturado);
    });

    // Add TotalPedido and TotalFacturado to each item in pedidosPorVendedor
    const updatedPedidosPorVendedor = resCalendar.pedidosPorVendedor.map(item => {
      const codVendedor = item.CodVendedor;

      return {
        ...item,
        nroPedidos: countPedidosMap.get(codVendedor) || 0,
        TotalPedido: totalPedidoMap.get(codVendedor) || 0,
        TotalFacturado: totalFacturadoMap.get(codVendedor) || 0,
      };
    });

    // Iterate over vendors and dates to fill in missing entries
    updatedPedidosPorVendedor.forEach(vendor => {
      // If the vendor is not already in the map, add it
      if (!uniqueVendors.has(vendor.CodVendedor)) {
        uniqueVendors.set(vendor.CodVendedor, { CodVendedor: vendor.CodVendedor, Nombre: vendor.Nombre, nroPedidos: vendor.nroPedidos, TotalPedido: vendor.TotalPedido, TotalFacturado: vendor.TotalFacturado });
      }
    });

    // Create a new array to store the filled data
    const filledData = [];

    // Iterate over unique vendors and dates to fill in missing entries
    uniqueVendors.forEach(vendor => {
      uniqueDates.forEach(date => {
        const existingEntry = updatedPedidosPorVendedor.find(entry => entry.CodVendedor === vendor.CodVendedor && entry.Fecha === date);
        if (existingEntry) {
          filledData.push({ Pedidos: existingEntry.Pedidos, CodVendedor: vendor.CodVendedor, Nombre: vendor.Nombre, Fecha: date, nroPedidos: vendor.nroPedidos, TotalPedido: vendor.TotalPedido, TotalFacturado: vendor.TotalFacturado });
        } else {
          // If the entry is missing, add it with 0 orders
          filledData.push({ Pedidos: 0, CodVendedor: vendor.CodVendedor, Nombre: vendor.Nombre, Fecha: date, nroPedidos: vendor.nroPedidos, TotalPedido: vendor.TotalPedido, TotalFacturado: vendor.TotalFacturado });
        }
      });
    });

    // Sort the filledData array by 'CodVendedor' (vendor code) and then by 'Fecha' (date)
    filledData.sort((a, b) => {
      if (a.CodVendedor !== b.CodVendedor) {
        return a.CodVendedor.localeCompare(b.CodVendedor); // Sort by vendor code
      } else {
        const dateA = new Date(a.Fecha);
        const dateB = new Date(b.Fecha);
        return dateA - dateB; // Sort by date
      }
    });

    setPedidosPorDia(filledData);
    const totalFactSinFlete = resCalendar.nroFacturas.reduce((acc, factura) => acc + factura.TotalFacturado, 0) - (resCalendar.nroFacturas.reduce((acc, factura) => acc + factura.TotalFacturado, 0) * flete)
    const totalPedSinFlete = resCalendar.nroPedidos.reduce((acc, factura) => acc + factura.TotalPedido, 0) - (resCalendar.nroPedidos.reduce((acc, factura) => acc + factura.TotalPedido, 0) * flete)
    setResumenFacturado(totalFactSinFlete);
    setResumenPedido(totalPedSinFlete);

    // Sumatoria total de metas
    let metasSum = 0
    for (let i = 0; i < ventasxZona.length; i++) {
      metasSum += ventasxZona[i].meta_zona;
    }
    setTotalMeta(metasSum)

    // Sumatoria de las ventas
    let vendidoSum = 0
    for (let i = 0; i < ventasxZona.length; i++) {
      vendidoSum += ventasxZona[i].total_vendido;
    }

    // Aqui se empieza a cuadrar todo sin flete
    let vendidoSinFlete = (vendidoSum - (vendidoSum * flete))
    let vendidoVert = (ventasYCantidad[0].TotalVendidoVert - (ventasYCantidad[0].TotalVendidoVert * flete))
    let vendidoIngco = (ventasYCantidad[0].TotalVendidoIngco - (ventasYCantidad[0].TotalVendidoIngco * flete))
    let vendidoImou = (ventasYCantidad[0].TotalVendidoImou - (ventasYCantidad[0].TotalVendidoImou * flete))
    let vendidoQuilosa = (ventasYCantidad[0].TotalVendidoQuilosa - (ventasYCantidad[0].TotalVendidoQuilosa * flete))
    let vendidoFleximatic = (ventasYCantidad[0].TotalVendidoFleximatic - (ventasYCantidad[0].TotalVendidoFleximatic * flete))
    let vendidoCorona = (ventasYCantidad[0].TotalVendidoCorona - (ventasYCantidad[0].TotalVendidoCorona * flete))
    let vendidoWadfow = (ventasYCantidad[0].TotalVendidoWadfow - (ventasYCantidad[0].TotalVendidoWadfow * flete))

    setTotalVendido(vendidoSinFlete)
    setTotalVendidoVert(vendidoVert)
    setTotalVendidoIngco(vendidoIngco)
    setTotalVendidoImou(vendidoImou)
    setTotalVendidoQuilosa(vendidoQuilosa)
    setTotalVendidoFleximatic(vendidoFleximatic)
    setTotalVendidoCorona(vendidoCorona)
    setTotalVendidoWadfow(vendidoWadfow)

    // Division de top de zonas
    if (zonasSup.length > 1) {
      setMultipleTops(true)

      const [resTopZona1, resTopZona2, resTopZona3] = await Promise.all([getTopVendedoresSupervisor(user.CodVendedor, zonasSup[0].Sector), getTopVendedoresSupervisor(user.CodVendedor, zonasSup[1].Sector), getTopVendedoresSupervisor(user.CodVendedor, zonasSup[2].Sector)])

      const TopChart1 = await resTopZona1;
      const TopChart2 = await resTopZona2;
      const TopChart3 = await resTopZona3;

      setTopData({
        options: {
          chart: {
            id: "chart-TopVendedores",
            type: 'bar',
          },
          xaxis: {
            categories: TopChart1.map((Vendedor) => Vendedor.Nombre),
          },
          legend: {
            show: true,
            position: 'bottom'
          },
          dataLabels: {
            enabled: true,
            style: {
              colors: ['#000']
            }
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
              distributed: true,
              labels: {
                show: true
              },
              dataLabels: {
                formatter: function (val, opt) {
                  const goals =
                    opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex]
                      .goals
                  if (goals && goals.length) {
                    return `${val} / ${goals[0].value}`
                  }
                  return val
                },
                enabled: true,
                style: {
                  colors: ['#000']
                }
              },
            },
          },
          colors: TopChart1.map((Vendedor) => Vendedor.ventas_vendedor < Vendedor.global / 2 ? '#ff0000b3' : Vendedor.ventas_vendedor < Vendedor.global * 0.8 ? '#fe9c1299' : '#27ae60b3'),
        },
        series: [
          {
            name: 'Ventas en USD',
            data: TopChart1.map((Vendedor) => ({
              x: Vendedor.Nombre,
              y: ((Vendedor.ventas_vendedor) - (Vendedor.ventas_vendedor * 0.031)).toFixed(2),
              goals: [
                {
                  name: 'Meta en USD',
                  value: Vendedor.global,
                  strokeWidth: 10,
                  strokeHeight: 0,
                  strokeLineCap: 'round',
                  strokeColor: '#e74c3c'
                }
              ]
            }))
          }
        ]
      })

      setTopData2({
        options: {
          chart: {
            id: "chart-TopVendedores",
            type: 'bar',
          },
          xaxis: {
            categories: TopChart2.map((Vendedor) => Vendedor.Nombre),
          },
          legend: {
            show: true,
            position: 'bottom'
          },
          dataLabels: {
            enabled: true,
            style: {
              colors: ['#000']
            }
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
              distributed: true,
              labels: {
                show: true
              },
            },
          },
          colors: TopChart2.map((Vendedor) => Vendedor.ventas_vendedor < Vendedor.global / 2 ? '#ff0000b3' : Vendedor.ventas_vendedor < Vendedor.global * 0.8 ? '#fe9c1299' : '#27ae60b3'),
        },
        series: [
          {
            name: 'Ventas en USD',
            data: TopChart2.map((Vendedor) => ({
              x: Vendedor.Nombre,
              y: ((Vendedor.ventas_vendedor) - (Vendedor.ventas_vendedor * 0.031)).toFixed(2),
              goals: [
                {
                  name: 'Meta en USD',
                  value: Vendedor.global,
                  strokeWidth: 10,
                  strokeHeight: 0,
                  strokeLineCap: 'round',
                  strokeColor: '#e74c3c'
                }
              ]
            }))
          }
        ]
      })

      setTopData3({
        options: {
          chart: {
            id: "chart-TopVendedores",
            type: 'bar',
          },
          xaxis: {
            categories: TopChart3.map((Vendedor) => Vendedor.Nombre),
          },
          legend: {
            show: true,
            position: 'bottom'
          },
          dataLabels: {
            enabled: true,
            style: {
              colors: ['#000']
            }
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
              distributed: true,
              labels: {
                show: true
              },
            },
          },
          colors: TopChart3.map((Vendedor) => Vendedor.ventas_vendedor < Vendedor.global / 2 ? '#ff0000b3' : Vendedor.ventas_vendedor < Vendedor.global * 0.8 ? '#fe9c1299' : '#27ae60b3'),
        },
        series: [
          {
            name: 'Ventas en USD',
            data: TopChart3.map((Vendedor) => ({
              x: Vendedor.Nombre,
              y: ((Vendedor.ventas_vendedor) - (Vendedor.ventas_vendedor * 0.031)).toFixed(2),
              goals: [
                {
                  name: 'Meta en USD',
                  value: Vendedor.global,
                  strokeWidth: 10,
                  strokeHeight: 0,
                  strokeLineCap: 'round',
                  strokeColor: '#e74c3c'
                }
              ]
            }))
          }
        ]
      })

    } else {
      const TopChart1 = await getTopVendedoresSupervisor(user.CodVendedor, zonasSup[0].Sector)

      setTopData({
        options: {
          chart: {
            id: "chart-TopVendedores",
            type: 'bar',
          },
          xaxis: {
            categories: TopChart1.map((Vendedor) => Vendedor.Nombre),
          },
          legend: {
            show: true,
            position: 'bottom'
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
              distributed: true,
              labels: {
                show: true
              },
              dataLabels: {
                enabled: false
              },
            },
          },
          colors: TopChart1.map((Vendedor) => Vendedor.ventas_vendedor < Vendedor.global / 2 ? '#ff0000b3' : Vendedor.ventas_vendedor < Vendedor.global * 0.8 ? '#fe9c1299' : '#27ae60b3'),
        },
        series: [
          {
            name: 'Ventas en USD',
            data: TopChart1.map((Vendedor) => ({
              x: Vendedor.Nombre,
              y: ((Vendedor.ventas_vendedor) - (Vendedor.ventas_vendedor * 0.031)).toFixed(2),
              goals: [
                {
                  name: 'Meta en USD',
                  value: Vendedor.global,
                  strokeWidth: 10,
                  strokeHeight: 0,
                  strokeLineCap: 'round',
                  strokeColor: '#e74c3c'
                }
              ]
            }))
          }
        ]
      })
    }

    const sortedVentasAnuales = ventasAnuales.map(venta => {
      const sortedData = Object.keys(venta)
        .sort()
        .reduce((obj, key) => {
          obj[key] = venta[key];
          return obj;
        }, {});
      return sortedData;
    });

    const seriesData = sortedVentasAnuales.map(venta => {
      const data = labels.map(label => venta[label] === undefined ? 0 : ((venta[label].toFixed(2)) - (venta[label].toFixed(2) * flete)).toFixed(2));
      return {
        name: `Ventas de ${venta.Sector} en USD`,
        data: data
      };
    });

    setVentasAnualesData({
      options: {
        chart: {
          id: "chart-VentasAnuales",
          type: "area"
        },
        xaxis: {
          categories: labels
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100]
          }
        },
        colors: ['#52d689', '#0041ff', '#d62915'],
        dataLabels: {
          enabled: false
        },
      },
      series: seriesData
    })

    setLoading(false)
  }

  return (
    <>
      <Layout>
        <div className="container-fluid">
          {
            (loading) ?
              <>
                <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                  <div className="loader-book">
                    <div>
                      <ul>
                        <li>
                          <svg fill="currentColor" viewBox="0 0 90 120">
                            <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                          </svg>
                        </li>
                        <li>
                          <svg fill="currentColor" viewBox="0 0 90 120">
                            <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                          </svg>
                        </li>
                        <li>
                          <svg fill="currentColor" viewBox="0 0 90 120">
                            <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                          </svg>
                        </li>
                        <li>
                          <svg fill="currentColor" viewBox="0 0 90 120">
                            <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                          </svg>
                        </li>
                        <li>
                          <svg fill="currentColor" viewBox="0 0 90 120">
                            <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                          </svg>
                        </li>
                        <li>
                          <svg fill="currentColor" viewBox="0 0 90 120">
                            <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                          </svg>
                        </li>
                      </ul>
                    </div>
                    <span>Cargando estadisticas...</span>
                  </div>
                </div>
              </> :
              <>
                {/* Div para movil */}
                <div className="text-center d-sm-block d-md-none">
                  <br />
                  <img src={notFoundImg} alt="..." onError={handleImageError} className="mx-auto w-100" />
                </div>
                {/* Div para web */}
                <div className='d-none d-md-block'>
                  <div className="container-fluid rounded">
                    <div className="row my-3">
                      <div className="col">
                        <span>
                          <h5><span className="badge rounded bg-primary">Inicio</span> <ChevronRight size={20} color="#000000" absoluteStrokeWidth /> <a href="/dashboard-supervisor">Panel de control</a></h5>
                        </span>
                      </div>
                      <div className="col text-end text-belmeny">
                        <div className="row">
                          <div className="col offset-6">
                            <img src={userIcon} alt="No se ha cargado la imagen..." className='float-end drop-shadow rounded-circle' style={{ width: "60px", height: "60px", border: "3px solid rgba(0, 41, 117, 1)" }} />
                          </div>
                          <div className="col d-flex align-items-center justify-content-center">
                            <h4>{user.Nombre}</h4>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Initial stats */}
                    <div className="row">
                      {/* Facturado */}
                      <div className="col-2 bg-white drop-shadow-card p-3 rounded">
                        <div className="ms-2 text-belmeny">
                          <h4>Ventas del mes:</h4>
                          <h6 className='fw-normal'>USD</h6>
                          <h5>Meta: ${formatNumber(totalMeta)}</h5>
                          <h5>Facturado: ${formatNumber(totalVendido.toFixed(2))}</h5>
                        </div>
                      </div>
                      <div className="col bg-white drop-shadow-card p-3 rounded mx-3">
                        <h4>Facturas vencidas:</h4>
                        <div className="row">
                          <div className="col">
                            <h6 className='fw-normal'>USD</h6>
                            <h5 className="text-danger">{facturasVencidas.Vencidas_1_15_Dias} facturas - ${formatNumber(facturasVencidas.Vencidas_1_15_Dias_TotalNeto.toFixed(2))}</h5>
                            <h6 className='fw-normal'>1 - 15 días</h6>
                          </div>
                          <div className="col">
                            <h6 className='fw-normal'>USD</h6>
                            <h5 className="text-danger">{facturasVencidas.Vencidas_15_29_Dias} facturas - ${formatNumber(facturasVencidas.Vencidas_15_29_Dias_TotalNeto.toFixed(2))}</h5>
                            <h6 className='fw-normal'>16 - 30 días</h6>
                          </div>
                          <div className="col">
                            <h6 className='fw-normal'>USD</h6>
                            <h5 className="text-danger">{facturasVencidas.Vencidas_30_Dias} facturas - ${formatNumber(facturasVencidas.Vencidas_30_Dias_TotalNeto.toFixed(2))}</h5>
                            <h6 className='fw-normal'>Más de 30 días</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-3 bg-white drop-shadow-card p-3 rounded">
                        <div className="row">
                          <h4>Facturas emitidas este mes:</h4>
                          <h5 className="text-belmeny">{facturasEmitidas} Facturas</h5>
                        </div>
                        <div className="row">
                          <h4>Clientes captados este mes:</h4>
                          <h5 className="text-belmeny">{clientesCaptados} Clientes</h5>
                        </div>
                      </div>
                    </div>

                    {/* CHART VENTAS DEL AÑO EN CURSO */}
                    <div className="row my-4">
                      <div className='col bg-white rounded drop-shadow-card me-3'>
                        <div className="h-100 p-2">
                          <h4 className="text-center text-belmeny fst-italic">Ventas anuales</h4>
                          <Chart
                            options={ventasAnualesData.options}
                            series={ventasAnualesData.series}
                            type="area"
                            width="100%"
                            height="450px"
                          />
                        </div>
                      </div>
                      <div className="col-3 drop-shadow-card">
                        <ModulosSupervisor>
                          {(buttonId) => handleButtonClick(buttonId)}
                        </ModulosSupervisor>
                      </div>
                    </div>

                    {/* Ventas zona y chart de clientes */}
                    <div className="row">
                      <div className='col drop-shadow-card'>
                        <div className="bg-white rounded h-100 p-2">
                          <h4 className="text-center text-belmeny fst-italic pt-2">Ventas del mes</h4>
                          <h5 className="text-center text-belmeny">Meta general a lograr: {formatNumber(totalMeta)} USD
                          </h5>
                          <Chart
                            options={ventasZonaData.options}
                            series={ventasZonaData.series}
                            type="bar"
                            width="100%"
                            height="100%"
                          />
                        </div>
                      </div>
                      <div className="col bg-white rounded drop-shadow-card me-3">
                        <div className="row mt-2 mb-3">
                          <div className="col">
                            <h4 className="text-center text-belmeny fst-italic pt-2">Top Clientes</h4>
                          </div>
                          <div className="col-3 text-end">
                            <button className="btn btn-hover-details" onClick={(e) => handleButtonClick('topClientes')}>Ver detalles paretos</button>
                          </div>
                          <div className="col-3 text-end">
                            <button className="btn btn-hover-details" onClick={(e) => handleButtonClick('topAllClientes')}>Ver todos los clientes</button>
                          </div>
                        </div>
                        {
                          <>
                            <div className="row my-3 px-3">
                              <div className="col text-start">
                                <h6 className='text-belmeny text-truncate' style={{ maxWidth: "450px" }}>Clientes</h6>
                              </div>
                              <div className="col-2 my-auto">
                                <h6 className='text-center text-belmeny'>Máximo Facturado por mes 2024</h6>
                              </div>
                              <div className="col-1 my-auto">
                                <h6 className='text-center text-belmeny'>-</h6>
                              </div>
                              <div className="col-2 my-auto">
                                <h6 className="text-center text-belmeny">Facturado</h6>
                              </div>
                              <div className="col-2 my-auto">
                                <h6 className="text-center text-belmeny">Opciones</h6>
                              </div>
                            </div>
                            {
                              (topClientes.data.length > 0) ?
                                (topClientes.data.slice(0, 10).map((cliente, idx) =>
                                  <div className="row my-3 px-3" key={idx}>
                                    <div className="col-5 text-start">
                                      <h6 className='text-belmeny text-truncate' style={{ maxWidth: "400px" }}>{cliente.NombreCliente}</h6>
                                      <h6 className='text-muted fw-normal fst-italic'>{cliente.NombreVendedor} - {cliente.Sector}</h6>
                                    </div>
                                    <div className="col-2 my-auto">
                                      <h6 className='text-center text-success'>{formatNumber(((cliente.Maximo_Facturado.toFixed(2)) - (cliente.Maximo_Facturado.toFixed(2) * 0.031)).toFixed(2))} USD</h6>
                                      <h6 className='text-muted fw-normal fst-italic text-center'>{cliente.Mes}</h6>
                                    </div>
                                    <div className="col-1 my-auto">
                                      <h6 className='text-center text-success'>-</h6>
                                    </div>
                                    <div className="col-2 my-auto">
                                      <h6 className={cliente.Facturado < cliente.Maximo_Facturado * 0.4 ? 'text-center text-danger' : (cliente.Facturado < cliente.Maximo_Facturado * 0.6 ? 'text-center text-warning' : 'text-center text-success')}>{formatNumber(((cliente.Facturado.toFixed(2)) - (cliente.Facturado.toFixed(2) * 0.031)).toFixed(2))} USD</h6>
                                      <h6 className='text-muted fw-normal fst-italic text-center'>Mes en curso</h6>
                                    </div>
                                    <div className="col-2 my-auto">
                                      <button className='btn btn-hover-details' onClick={(e) => { handleButtonClick('historicoCliente'); setClienteSelected(cliente.CodigoCliente) }}>Ver Historico</button>
                                    </div>
                                  </div>
                                )) : <h4 className="text-center text-belmeny fst-italic mt-5 alert alert-info">No hay información de clientes paretos existente...</h4>
                            }
                            <h6 className='text-muted fw-normal fst-italic text-center'>Nota: El monto cambiará de color según el % en relación con el máximo facturado</h6>
                            <h6 className='text-muted fw-normal fst-italic text-center'>De 0% a 40% se refleja en <span className="text-danger fw-bold">rojo</span></h6>
                            <h6 className='text-muted fw-normal fst-italic text-center'>De 41% a 59% se refleja en <span className="text-warning fw-bold">amarillo</span></h6>
                            <h6 className='text-muted fw-normal fst-italic text-center'>Más del 60% se refleja en <span className="text-success fw-bold">verde</span></h6>
                          </>
                        }
                      </div>
                    </div>

                    <div className="division my-5"></div>

                    {/* Cobranzas por vendedor */}
                    <div className="mb-5 bg-white rounded drop-shadow-card rounded p-3">
                      <h4 className="text-center text-belmeny fst-italic mb-1">Cobranzas de su gerencia</h4>
                      <h6 className="text-muted fst-italic fw-normal text-center">Las gráficas serán mostradas dependiendo si el mes tiene una deuda pendiente.</h6>
                      <div className="accordion mt-2" id="accordionCobranzas">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingLastYear">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseLastYear" aria-expanded="true" aria-controls="collapseLastYear">
                              <h5 className="text-belmeny fst-italic text-center mt-2">Año 2023</h5>
                            </button>
                          </h2>
                          <div id="collapseLastYear" className="accordion-collapse collapse show" aria-labelledby="headingLastYear" data-bs-parent="#accordionCobranzas">
                            <div className="accordion-body">
                              {
                                resultCobranzas2023.map((result, idx) =>
                                  idx % 4 === 0 ? (
                                    <div key={idx} className="row mb-2">
                                      {[0, 1, 2, 3].map((colIdx) => {
                                        const currentIndex = idx + colIdx;
                                        if (currentIndex < resultCobranzas2023.length && resultCobranzas2023[currentIndex].Pendiente > 0) {
                                          return (
                                            <div key={currentIndex} className="col">
                                              <h5 className="text-center text-belmeny text-capitalize">{resultCobranzas2023[currentIndex].Mes}</h5>
                                              <Chart
                                                options={{
                                                  labels: [`${(parseFloat(resultCobranzas2023[currentIndex].Pagado * 100) / (resultCobranzas2023[currentIndex].Pagado + resultCobranzas2023[currentIndex].Pendiente)).toFixed(2)}% Pagado`, `${(parseFloat(resultCobranzas2023[currentIndex].Pendiente * 100) / (resultCobranzas2023[currentIndex].Pagado + resultCobranzas2023[currentIndex].Pendiente)).toFixed(2)}% Pendiente`],
                                                  legend: {
                                                    show: true,
                                                    position: "bottom",
                                                  },
                                                  plotOptions: {
                                                    pie: {
                                                      pie: {
                                                        labels: {
                                                          show: true
                                                        }
                                                      },
                                                      dataLabels: {
                                                        enabled: true,
                                                      },
                                                    },
                                                  },
                                                  colors: ['#27ae60b3', '#ff0000b3']
                                                }}
                                                series={[resultCobranzas2023[currentIndex].Pagado, resultCobranzas2023[currentIndex].Pendiente]}
                                                type="pie"
                                                width="350px"
                                                className="d-flex align-middle justify-content-center"
                                              />
                                              <button className='buttonMonths w-100 my-2' onClick={(e) => {
                                                handleButtonClick("cobranzasGerencia")
                                                setMesSelected(resultCobranzas2023[currentIndex].Mes)
                                                setAñoSelected(resultCobranzas2023[currentIndex].Año)
                                              }}><span>Ver detalles de</span> <span className="text-capitalize">{resultCobranzas2023[currentIndex].Mes}</span></button>
                                            </div>
                                          );
                                        } else {
                                          return null;
                                        }
                                      })}
                                    </div>
                                  ) : null
                                )
                              }
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingActualYear">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseActualYear" aria-expanded="false" aria-controls="collapseActualYear">
                              <h5 className="text-belmeny fst-italic text-center mt-2">Año 2024</h5>
                            </button>
                          </h2>
                          <div id="collapseActualYear" className="accordion-collapse collapse" aria-labelledby="headingActualYear" data-bs-parent="#accordionCobranzas">
                            <div className="accordion-body">
                              {
                                resultCobranzas2024.map((result, idx) =>
                                  idx % 4 === 0 ? (
                                    <div key={idx} className="row mb-2">
                                      {[0, 1, 2, 3].map((colIdx) => {
                                        const currentIndex = idx + colIdx;
                                        if (currentIndex < resultCobranzas2024.length && resultCobranzas2024[currentIndex].Pendiente > 0) {
                                          return (
                                            <div key={currentIndex} className="col">
                                              <h5 className="text-center text-belmeny text-capitalize">{resultCobranzas2024[currentIndex].Mes}</h5>
                                              <Chart
                                                options={{
                                                  labels: [`${(parseFloat(resultCobranzas2024[currentIndex].Pagado * 100) / (resultCobranzas2024[currentIndex].Pagado + resultCobranzas2024[currentIndex].Pendiente)).toFixed(2)}% Pagado`, `${(parseFloat(resultCobranzas2024[currentIndex].Pendiente * 100) / (resultCobranzas2024[currentIndex].Pagado + resultCobranzas2024[currentIndex].Pendiente)).toFixed(2)}% Pendiente`],
                                                  legend: {
                                                    show: true,
                                                    position: "bottom",
                                                  },
                                                  plotOptions: {
                                                    pie: {
                                                      pie: {
                                                        labels: {
                                                          show: true
                                                        }
                                                      },
                                                      dataLabels: {
                                                        enabled: true,
                                                      },
                                                    },
                                                  },
                                                  colors: ['#27ae60b3', '#ff0000b3']
                                                }}
                                                series={[resultCobranzas2024[currentIndex].Pagado, resultCobranzas2024[currentIndex].Pendiente]}
                                                type="pie"
                                                width="350px"
                                                className="d-flex align-middle justify-content-center"
                                              />
                                              <button className='buttonMonths w-100 my-2' onClick={(e) => {
                                                handleButtonClick("cobranzasGerencia")
                                                setMesSelected(resultCobranzas2024[currentIndex].Mes)
                                                setAñoSelected(resultCobranzas2024[currentIndex].Año)
                                              }}><span>Ver detalles de</span> <span className="text-capitalize">{resultCobranzas2024[currentIndex].Mes}</span></button>
                                            </div>
                                          );
                                        } else {
                                          return null;
                                        }
                                      })}
                                    </div>
                                  ) : null
                                )
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
          }
        </div>
      </Layout>

      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header className='bg-belmeny text-light'>
          <Modal.Title className='fst-italic'>{modalTitle}</Modal.Title>
          <Modal.Title>
            <RiCloseCircleFill className='text-danger fs-1 modal-close bg-light rounded-circle' onClick={() => setShow(false)} />
          </Modal.Title>
        </Modal.Header>
        <div className="division w-100 my-2 bg-belmeny"></div>
        <Modal.Body>

          <div className="container-fluid">
            {/* Div para escritorio */}
            <div className='d-none d-md-block d-lg-block'>

              {
                (loading) ?
                  <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                    <div className="loader-book">
                      <div>
                        <ul>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                        </ul>
                      </div>
                      <span>Cargando {modalLoading}...</span>
                    </div>
                  </div> :
                  <>
                    {
                      (modalBody === 'pedidosPorDia') ?
                        <>
                          <div className="row my-4">
                            {
                              zonasSupervisor.map((Zona) =>
                                <div className='col'>
                                  <button className='buttonMonths w-100' onClick={(e) => setZonaSelected(Zona.Sector)}><span className="text-white">{Zona.Sector}</span>
                                  </button>
                                </div>)
                            }
                            <div className="col"><button className='buttonMonths w-100' onClick={(e) => setZonaSelected('General')}><span className="text-white">General</span></button></div>
                          </div>
                          <h5 className='text-belmeny my-2 ps-2'>Zona seleccionada: {zonaSelected}</h5>
                          <Stack direction="row" spacing={10} alignItems="flex-start" className='mb-3'>
                            <DateRangePicker
                              appearance={"subtle"}
                              ranges={predefinedRanges}
                              placeholder="Buscar por fecha"
                              style={{ width: 300 }}
                              onChange={e => changeDate(e)}
                              className="border rounded-pill mb-2"
                              showOneCalendar
                              disabledDate={afterToday()}
                            />
                          </Stack>
                          <PedidosPorDiaTable data={pedidosPorDia} resumenFacturado={resumenFacturado} resumenPedido={resumenPedido} />
                        </> : (modalBody === 'topClientes') ?
                          <TopClientes topClientes={topClientes} supervisor={user.CodVendedor} loaded={'paretos'} />
                          : (modalBody === 'topAllClientes') ?
                            <TopClientes topClientes={topAllClientes} supervisor={user.CodVendedor} loaded={'todos'} />
                            : (modalBody === 'topVendedores') ?
                              <TopVendedores zonasSupervisor={zonasSupervisor} multipleTops={multipleTops} topData={topData} topData2={topData2} topData3={topData3} />
                              : (modalBody === 'topProductos') ?
                                <TopProductosSupervisor totalVendido={totalVendido} ventasProductos={ventasProductos} totalVendidoVert={totalVendidoVert} totalVendidoIngco={totalVendidoIngco} totalVendidoQuilosa={totalVendidoQuilosa} totalVendidoImou={totalVendidoImou} totalVendidoFleximatic={totalVendidoFleximatic} totalVendidoCorona={totalVendidoCorona} totalVendidoWadfow={totalVendidoWadfow} />
                                : (modalBody === 'corteSemanal') ?
                                  <CorteSemanalSupervisor startPastWeek={startPastWeek} endPastWeek={endPastWeek} startCurrentWeek={startCurrentWeek} endCurrentWeek={endCurrentWeek} zonasSupervisor={zonasSupervisor} ventasSemanales={ventasSemanales} ventasGralZona={ventasGralZona} ventasSemanalesOtrasZonas={ventasSemanalesOtrasZonas} />
                                  : (modalBody === 'cobranzasGerencia') ?
                                    <CobranzasGerencia mes={mesSelected} año={añoSelected} />
                                    : (modalBody === 'historicoCliente') ?
                                      <HistoricoCliente cliente={clienteSelected} />
                                      : null
                    }
                  </>
              }
            </div>

            {/* Div para móvil */}
            <div className='d-sm-block d-md-none'>
              {
                (loading) ?
                  <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                    <div className="loader-book">
                      <div>
                        <ul>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                        </ul>
                      </div>
                      <span>Cargando {modalLoading}...</span>
                    </div>
                  </div> :
                  <>
                    {
                      (modalBody === 'pedidosPorDia') ?
                        <>
                          <div className="row my-4">
                            {
                              zonasSupervisor.map((Zona) =>
                                <div className='col'>
                                  <button className='buttonMonths w-100' onClick={(e) => setZonaSelected(Zona.Sector)}><span className="text-white">{Zona.Sector}</span>
                                  </button>
                                </div>)
                            }
                            <div className="col"><button className='buttonMonths w-100' onClick={(e) => setZonaSelected('General')}><span className="text-white">General</span></button></div>
                          </div>
                          <h5 className='text-belmeny my-2 ps-2'>Zona seleccionada: {zonaSelected}</h5>
                          <Stack direction="row" spacing={10} alignItems="flex-start" className='mb-3'>
                            <DateRangePicker
                              appearance={"subtle"}
                              ranges={predefinedRanges}
                              placeholder="Buscar por fecha"
                              style={{ width: 300 }}
                              onChange={e => changeDate(e)}
                              className="border rounded-pill mb-2"
                              showOneCalendar
                              disabledDate={afterToday()}
                            />
                          </Stack>
                          <PedidosPorDiaTable data={pedidosPorDia} resumenFacturado={resumenFacturado} resumenPedido={resumenPedido} />
                        </> : (modalBody === 'topClientes') ?
                          <TopClientes topClientes={topClientes} supervisor={user.CodVendedor} />
                          : (modalBody === 'topVendedores') ?
                            <TopVendedores zonasSupervisor={zonasSupervisor} multipleTops={multipleTops} topData={topData} topData2={topData2} topData3={topData3} />
                            : (modalBody === 'topProductos') ?
                              <TopProductosSupervisor totalVendido={totalVendido} ventasProductos={ventasProductos} totalVendidoVert={totalVendidoVert} totalVendidoIngco={totalVendidoIngco} totalVendidoQuilosa={totalVendidoQuilosa} totalVendidoImou={totalVendidoImou} totalVendidoFleximatic={totalVendidoFleximatic} totalVendidoCorona={totalVendidoCorona} totalVendidoWadfow={totalVendidoWadfow} />
                              : (modalBody === 'corteSemanal') ?
                                <CorteSemanalSupervisor startPastWeek={startPastWeek} endPastWeek={endPastWeek} startCurrentWeek={startCurrentWeek} endCurrentWeek={endCurrentWeek} zonasSupervisor={zonasSupervisor} ventasSemanales={ventasSemanales} ventasGralZona={ventasGralZona} ventasSemanalesOtrasZonas={ventasSemanalesOtrasZonas} />
                                : null
                    }
                  </>
              }
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}